<template lang="pug">
.brand-affiliate-onboarding.w-100
  om-select-cards.w-100(v-model="chosen" :options="options")
</template>

<script>
  import { mapGetters } from 'vuex';
  import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';

  export default {
    data() {
      return {
        hovered: '',
        chosen: null,
      };
    },

    computed: {
      ...mapGetters(['isMobile']),
      options() {
        return [
          ...(!this.isMobile
            ? [
                {
                  icon: 'partner',
                  key: this.$t('onboarding.affiliate.cards.referral'),
                  value: 'referral',
                },
              ]
            : []),
          {
            icon: 'layout',
            key: this.$t('onboarding.affiliate.cards.ui'),
            value: 'ui',
          },
          {
            icon: 'phone',
            key: this.$t('onboarding.affiliate.cards.phone'),
            value: 'phone',
          },
        ];
      },
    },

    watch: {
      async chosen(v) {
        await this.$store.dispatch('finishOnboarding');

        switch (v) {
          case 'referral': {
            this.$router.push({ name: 'ambassador_dashboard' });
            break;
          }
          case 'ui': {
            this.$router.push({ name: 'dashboard' });
            break;
          }
          case 'phone': {
            this.$router.push({ name: accountSetupRouteNames.AFFILIATE_CALLENDLY });
            break;
          }
        }
      },
    },
  };
</script>

<style lang="sass">
  .brand-affiliate-onboarding
    display: flex
    flex-direction: column

    &-card
      position: relative
      display: flex
      align-items: center
      border-radius: 5px
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07)
      margin-bottom: 0.75rem
      font-size: 1.375rem
      line-height: 1.625rem
      font-weight: 500
      background-color: white
      height: 6.25rem
      padding-left: 6.6875rem
      padding-right: 1.3125rem
      cursor: pointer
      transition: .6s

      &:hover
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15)

        i
          transform: translateX(6px)

      &:last-child
        margin-bottom: 0

      &-svg
        position: absolute
        left: 0.5rem

      i
        transition: .6s
        margin-left: auto
        color: #ED5A29
</style>
